import React, { Fragment, useEffect, useLayoutEffect, useState } from 'react';
import { Alert, Button, Col, Container, Form, Row, Table } from 'react-bootstrap';
import axiosInstance from '../../utilities/axios_instance';
import { useTranslation } from 'react-i18next';
import { FaCheck, FaEnvelope, FaWhatsapp, FaTimes } from 'react-icons/fa'; // Import icons

const NextEventReport = () => {
    const apiUrl = process.env.REACT_APP_REPORT_API;
    const wabUrl = process.env.REACT_APP_MESSAGING_API;
    const { t } = useTranslation();
    const [file, setFile] = useState(null);
    const [events, setEvents] = useState([]);
    const [eventId, setEventId] = useState('');
    const [uploadedId, setUploadedId] = useState('');
    const [reportData, setReportData] = useState([]);
    const [message, setMessage] = useState('');
    const [error, setError] = useState(false);
    const [whatsappStatus, setWhatsappStatus] = useState({}); // Status tracking for WhatsApp messages

    const eventsAPIUrl = process.env.REACT_APP_EVENTS_API;
    const wsEndpoint = process.env.REACT_APP_WEBSOCKET;
    const ws = new WebSocket(wsEndpoint); // Replace with WebSocket server URL

    useEffect(() => {
        const fetchEvents = async () => {
          try {
            const response = await axiosInstance.get(`${eventsAPIUrl}/events`, { params: { ordered: 'true' } });
            if (Array.isArray(response.data.events)) {
              setEvents(response.data.events);
            } else {
              console.error("Unexpected API response format:", response.data);
            }
          } catch (error) {
            console.error(t('prepare_event.error_fetching_events'), error.message);
          }
        };
        fetchEvents();
      }, []);
    
    // WebSocket event listener for incoming messages
    useEffect(() => {
        ws.onmessage = (event) => {
            const updatedMember = JSON.parse(event.data);
            if (updatedMember.eventId === eventId) {
                setReportData((prevReportData) =>
                    prevReportData.map((member) =>
                        member.id === updatedMember.id
                            ? { ...member, contacted: updatedMember.contacted }
                            : member
                    )
                );
            }
        };

        // Close WebSocket connection on component unmount
        return () => {
            ws.close();
        };
    }, [eventId, ws]);

    const handleContactedChange = async (ev, contacted, memberId, member) => {
        try {
            // Update contact status in the backend
            await axiosInstance.post(`${apiUrl}/update_contacted_status`, {
                contacted,
                memberId,
                eventId,
                uploadedId,
            });

            // Update local state
            member.contacted = contacted;
            ev.target.checked = contacted;

            // Broadcast the change to other clients
            ws.send(JSON.stringify({ id: memberId, eventId: eventId, contacted }));

            ev.preventDefault();
        } catch (error) {
            console.error('Error updating contacted status:', error);
        }
    };

    // Handle file selection
    const handleFileChange = (e) => {
        setFile(e.target.files[0]);
    };

    // Handle file upload and event ID
    const handleUpload = async () => {
        if (!file || !eventId) {
            alert(t('next_event.alert.selectBoth')); // Ensure both file and event ID are provided
            return;
        }
        const formData = new FormData();
        formData.append('attendance', file);
        formData.append('eventID', eventId);

        try {
            const response = await axiosInstance.post(`${apiUrl}/next_event_contacts`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });

            if (response.data.success) {
                setReportData(response.data.report);
                setUploadedId(response.data.uploadedID);
                setError(false);
                setMessage(t('next_event.alert.updateCorrect'));
            } else {
                alert(t('next_event.alert.noAttendees')); // No members likely to attend
            }
        } catch (error) {
            console.error('Error uploading the file:', error);
            setError(true);
            setMessage(t('next_event.alert.errorUploading') + error);
        }
    };

    // Email invitation handler
    const sendEmailInvitation = (userId) => {
        axiosInstance
            .post(`${apiUrl}/send_email_invitation`, { userId, eventId })
            .then(() => {
                setMessage(t('next_event.alert.emailSent'));
                setError(false);
            })
            .catch((err) => {
                setError(true);
                setMessage(t('next_event.alert.errorSendingEmail') + err);
            });
    };

    // WhatsApp message handler
    const sendWhatsAppMessage = (userId) => {
        setWhatsappStatus((prev) => ({ ...prev, [userId]: 'loading' })); // Set loading state
        axiosInstance
            .post(`${wabUrl}/send_wab_invite`, { userId, eventId })
            .then(() => {
                setWhatsappStatus((prev) => ({ ...prev, [userId]: 'success' })); // Mark as success

                handleContactedChange(eventId, true, userId, uploadedId);
                setMessage(t('next_event.wab_ok'));
                setError(false);
            })
            .catch(() => {
                setWhatsappStatus((prev) => ({ ...prev, [userId]: 'failure' })); // Mark as failure
                setMessage(t('next_event.wab_ko'));
                setError(true);
            });
    };

    useLayoutEffect(() => {
        const formData = new FormData();
        formData.append('eventID', eventId);
        axiosInstance
            .post(`${apiUrl}/next_event_contacts`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            })
            .then((response) => {
                if (response.data.success) {
                    setReportData(response.data.report);
                    setEventId(response.data.eventID);
                    setUploadedId(response.data.uploadedID);
                } else {
                    alert(t('next_event.alert.noAttendees')); // No members likely to attend
                }
            })
            .catch((error) => {
                console.error('Error requesting default event:', error);
                setError(true);
                setMessage(t('next_event.alert.errorUploading') + error);
            });
    }, [eventId]);

    return (
        <Fragment>
            <div className="container">
                <h2>{t('next_event.heading.uploadXLS')}</h2>
                {message && (
                    <Alert variant={error ? 'danger' : 'success'} onClose={() => setMessage('')} dismissible>
                        {message}
                    </Alert>
                )}
                <Container>
                    <Row className="mb-4">
                        <Col sm="3">

                        <Form.Group  className="mb-2" controlId="eventSelect">
                            <Form.Label>{t('next_event.eventID')}</Form.Label>
                                        <Form.Select
                                          value={eventId}
                                          onChange={(e) => setEventId(e.target.value)}
                                          required
                                        >
                                          <option value="">{t('prepare_event.select_event_placeholder')}</option>
                                          {events.map((event) => (
                                            <option key={event.id} value={event.id}>
                                              {`${event.name} (${new Date(event.date).toLocaleDateString()})`}
                                            </option>
                                          ))}
                                        </Form.Select>
                                      </Form.Group>
                            {/* Event ID input 
                            <Form.Group className="mb-2">
                                <Form.Label>{t('next_event.eventID')}</Form.Label>
                                <Form.Control
                                    type="text"
                                    onChange={(e) => setEventId(e.target.value)}
                                    value={eventId}
                                />
                            </Form.Group>*/}
                        </Col>
                        <Col sm="5">
                            {/* File input */}
                            <Form.Group className="mb-2">
                                <Form.Label>{t('next_event.attendance')}</Form.Label>
                                <Form.Control type="file" accept=".xls,.xlsx,.csv" onChange={handleFileChange} />
                            </Form.Group>
                        </Col>
                    </Row>

                    <Button variant="primary" onClick={handleUpload}>
                        {t('next_event.button.upload')}
                    </Button>
                </Container>

                {/* Display Report Table */}
                {reportData.length > 0 && (
                    <div className="mt-5">
                        <h3>{t('next_event.heading.predictedAttendees')}</h3>
                        <Table striped bordered hover>
                            <thead>
                                <tr>
                                    <th>{t('next_event.table.userId')}</th>
                                    <th>{t('next_event.table.alias')}</th>
                                    <th>{t('next_event.table.firstName')}</th>
                                    <th>{t('next_event.table.lastName')}</th>
                                    <th>{t('next_event.table.phone')}</th>
                                    <th>{t('next_event.table.likelihood')}</th>
                                    <th>{t('next_event.table.contacted')}</th>
                                    <th className="center contain">{t('next_event.table.actions')}</th> {/* Actions column */}
                                </tr>
                            </thead>
                            <tbody>
                                {reportData.map((attendee, index) => (
                                    <tr key={index}>
                                        <td>{attendee.numSocio}</td>
                                        <td>{attendee.alias}</td>
                                        <td>{attendee.firstname}</td>
                                        <td>{attendee.lastname}</td>
                                        <td>{attendee.telefono}</td>
                                        <td className="text-center">{attendee.likelihood}%</td>
                                        <td className="text-center">
                                            <Form.Check
                                                type="checkbox"
                                                checked={attendee.contacted}
                                                onChange={(e) => handleContactedChange(e, e.target.checked, attendee.id, attendee)}
                                            />
                                        </td>
                                        <td className="text-center" style={{ minWidth: '120px' }}>
                                            {/* Email and WhatsApp buttons */}
                                            <Button
                                                variant="info"
                                                onClick={() => sendEmailInvitation(attendee.numSocio)}
                                                className="mr-2"
                                            >
                                                <FaEnvelope />
                                            </Button>
                                            <Button
                                                variant={ whatsappStatus[attendee.id] == 'failure'?'danger':'success'}   
                                                onClick={() => sendWhatsAppMessage(attendee.id)}
                                                disabled={whatsappStatus[attendee.id] === 'loading'|| whatsappStatus[attendee.id] === 'success' }
                                            >
                                                {whatsappStatus[attendee.id] === 'success' && <FaCheck />}
                                                {whatsappStatus[attendee.id] === 'failure' && <FaTimes />}
                                                {whatsappStatus[attendee.id] !== 'success' &&
                                                    whatsappStatus[attendee.id] !== 'failure' && <FaWhatsapp />}
                                            </Button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </div>
                )}
                
            </div>
        </Fragment>
    );
};

export default NextEventReport;
