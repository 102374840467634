import React, { useState, useEffect } from 'react';
import { Table, Button, Form, Col, Row, Modal, Card, Pagination, ToastContainer, Toast} from 'react-bootstrap';
import axiosInstance from '../../utilities/axios_instance';
import '../../i18n';
import { useTranslation } from 'react-i18next';
import { FaAlignJustify, FaEdit, FaEraser,FaMeetup, FaYoutubeSquare } from 'react-icons/fa';

const ManageEvents = () => {
  const { t } = useTranslation();
  const apiUrl = process.env.REACT_APP_EVENTS_API;
  const [events, setEvents] = useState([]);
  const [showForm, setShowForm] = useState(false);
  const [newEvent, setNewEvent] = useState({
    id: '',
    name: '',
    date: '',
    review: '',
    summary: '',
    meetup_id: '',
    comments: '',
    RSVP_No: 0,
    RSVP_No_LastMinute: 0,
    NoShows: 0,
    meetup_id: '',
  });

  const [editEvent, setEditEvent] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [sortConfig, setSortConfig] = useState({ key: 'id', direction: 'desc' });
  const [toast, setToast] = useState({ show: false, message: '', variant: '' });

  const rowsPerPage = 10;

  useEffect(() => {
    fetchEvents();
  }, []);

  const fetchEvents = async () => {
    try {
        
      const response = await axiosInstance.get(`${apiUrl}/events`);
      setEvents(response.data.events);
    } catch (error) {
      console.error('Error fetching events:', error);
      setToast({ show: false, message: '', variant: '' });
      setToast({ show: true, message: t('events.fetch_error'), variant: 'danger' });

    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (editEvent) {
      setEditEvent({ ...editEvent, [name]: value });
    } else {
      setNewEvent({ ...newEvent, [name]: value });
    }
  };

  const validateEvent = (event) => {
    const urlRegex = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/i;
  
    if (!event.id || isNaN(event.id)) {
      setToast({ show: true, message: t('events.validation_error_id'), variant: 'danger' });
      return false;
    }
  
    if (!event.name || event.name.trim().length < 3) {
      setToast({ show: true, message: t('events.validation_error_name'), variant: 'danger' });
      return false;
    }
  
    if (!newEvent.date) {
        setToast({ show: true, message: t('events.validation_error_date_required'), variant: 'danger' });
        return false;
    }

    if (event.meetup_id && isNaN(event.meetup_id)) {
      setToast({ show: true, message: t('events.validation_error_meetup_id'), variant: 'danger' });
      return false;
    }
  
    if (event.review && !urlRegex.test(event.review)) {
      setToast({ show: true, message: t('events.validation_error_review_url'), variant: 'danger' });
      return false;
    }
  
    if (event.summary && !urlRegex.test(event.summary)) {
      setToast({ show: true, message: t('events.validation_error_summary_url'), variant: 'danger' });
      return false;
    }
  
    return true;
  };
  

  const handleCreateEvent = async () => {
    if (!validateEvent(newEvent)) return; // Stop if validation fails
  
    try {
      setToast({ show: false, message: '', variant: '' });
      await axiosInstance.post(`${apiUrl}/events`, newEvent);
      setToast({ show: true, message: t('events.create_success', { id: newEvent.id }), variant: 'success' });
  
      setNewEvent({
        id: '',
        name: '',
        date: '',
        review: '',
        summary: '',
        meetup_id: '',
        comments: '',
        RSVP_No: 0,
        RSVP_No_LastMinute: 0,
        NoShows: 0,
      });
      setShowForm(false);
      fetchEvents();
    } catch (error) {
      console.error('Error creating event:', error);
      debugger;
      setToast({ show: true, message: t('events.create_error', { id: newEvent.id,error:error.response?.data?.error }), variant: 'danger' });
    }
  };
  

  const handleEditEvent = async () => {
    if (!validateEvent(editEvent)) return; // Stop if validation fails
  
    try {
      setToast({ show: false, message: '', variant: '' });
      await axiosInstance.put(`${apiUrl}/events/${editEvent.id}`, editEvent);
      setToast({ show: true, message: t('events.edit_success', { id: editEvent.id }), variant: 'success' });
      setEditEvent(null);
      fetchEvents();
    } catch (error) {
      console.error('Error editing event:', error);
      setToast({ show: true, message: t('events.edit_error', { id: editEvent.id,error:error.response?.data?.error }), variant: 'danger' });
    }
  };
  

  const handleDeleteEvent = async (id) => {
    if (window.confirm(t('events.confirm_delete',{id:id}))) {
        setToast({ show: false, message: '', variant: '' });
      try {
        await axiosInstance.delete(`${apiUrl}/events/${id}`);
        setToast({ show: true, message: t('events.delete_success',{id:id}), variant: 'success' });
        fetchEvents();
      } catch (error) {
        console.error('Error deleting event:', error);
        setToast({ show: true, message: t('events.delete_error',{id:id,error:error.response?.data?.error}), variant: 'danger' });
      }
    }
  };

  const sortedEvents = [...events].sort((a, b) => {
    if (a[sortConfig.key] < b[sortConfig.key]) {
      return sortConfig.direction === 'asc' ? -1 : 1;
    }
    if (a[sortConfig.key] > b[sortConfig.key]) {
      return sortConfig.direction === 'asc' ? 1 : -1;
    }
    return 0;
  });

  const eventsBelow1000 = sortedEvents.filter((event) => event.id < 1000);
  const eventsAboveOrEqual1000 = sortedEvents.filter((event) => event.id >= 1000);

  const paginatedEventsBelow1000 = eventsBelow1000.slice(
    (currentPage - 1) * rowsPerPage,
    currentPage * rowsPerPage
  );

  const paginatedEventsAboveOrEqual1000 = eventsAboveOrEqual1000.slice(
    (currentPage - 1) * rowsPerPage,
    currentPage * rowsPerPage
  );

  const handleSort = (key) => {
    setSortConfig((prevState) => {
      if (prevState.key === key) {
        return { key, direction: prevState.direction === 'asc' ? 'desc' : 'asc' };
      }
      return { key, direction: 'asc' };
    });
  };

  const totalPagesBelow1000 = Math.ceil(eventsBelow1000.length / rowsPerPage);
  const totalPagesAboveOrEqual1000 = Math.ceil(eventsAboveOrEqual1000.length / rowsPerPage);

  return (
    <div className="container mt-4">
      <h2>{t('events.title')}</h2>
        {false && <Button variant="primary" onClick={() => setToast({ show: true, message: t('events.fetch_error'), variant: 'success ' })} className="mb-3"> Show toast </Button>}
      <Button variant="primary" onClick={() => setShowForm(!showForm)} className="mb-3">
        {showForm ? t('events.hide_form') : t('events.new_event')}
      </Button>

      {showForm && (
        <Card className="mb-3">
          <Card.Body>
            <h4>{t('events.form_title')}</h4>
            <Form>
              <Row>
                <Col>
                  <Form.Group>
                    <Form.Label>{t('events.id')}*</Form.Label>
                    <Form.Control
                      type="number"
                      name="id" required="true"
                      value={newEvent.id}
                      onChange={handleInputChange}
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group>
                    <Form.Label>{t('events.name')}*</Form.Label>
                    <Form.Control
                      type="text"
                      name="name" required="true"
                      value={newEvent.name}
                      onChange={handleInputChange}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group>
                    <Form.Label>{t('events.date')}</Form.Label>
                    <Form.Control
                      type="date"
                      name="date" required="true"
                      value={newEvent.date.split('T')[0]}
                      onChange={handleInputChange}
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group>
                    <Form.Label>{t('events.meetup_id')}</Form.Label>
                    <Form.Control
                      type="text"
                      name="meetup_id"
                      value={newEvent.meetup_id}
                      onChange={handleInputChange}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group>
                    <Form.Label>{t('events.review_url')}</Form.Label>
                    <Form.Control
                      type="text"
                      name="review"
                      value={newEvent.review}
                      onChange={handleInputChange}
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group>
                    <Form.Label>{t('events.summary_url')}</Form.Label>
                    <Form.Control
                      type="text"
                      name="summary"
                      value={newEvent.summary}
                      onChange={handleInputChange}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Button variant="success" onClick={handleCreateEvent}>
                {t('events.create')}
              </Button>
            </Form>
          </Card.Body>
        </Card>
      )}

       <h3>{t('events.pianomeetups_title')}</h3>
      <Table striped bordered hover>
        <thead>
            <tr>
                <th onClick={() => handleSort('id')}>
                {t('events.id')} {sortConfig.key === 'id' && (sortConfig.direction === 'asc' ? '↑' : '↓')}
                </th>
                <th onClick={() => handleSort('name')}>
                {t('events.name')} {sortConfig.key === 'name' && (sortConfig.direction === 'asc' ? '↑' : '↓')}
                </th>
                <th onClick={() => handleSort('date')}>
                {t('events.date')} {sortConfig.key === 'date' && (sortConfig.direction === 'asc' ? '↑' : '↓')}
                </th>
                <th>{t('events.links')}</th>
                <th>{t('events.actions')}</th>
            </tr>
        </thead>
        <tbody>
          {paginatedEventsBelow1000.map((event) => (
            <tr key={event.id}>
              <td>{event.id}</td>
              <td>{event.name}</td>
              <td>{event.date.split('T')[0]}</td>
              <td className='text-center'>
                {event.review &&<a href={event.review} target="_blank" className="btn btn-secondary " rel="noopener noreferrer" alt={t('events.review')}>
                  <FaAlignJustify /> 
                </a>  }
                {event.summary &&<a href={event.summary} target="_blank" className="btn btn-secondary" rel="noopener noreferrer" alt={t('events.summary')}>
                  <FaYoutubeSquare  /> 
                </a> }
                {event.meetup_id &&<a href={`https://meetup.com/madrid-pianomeetups/events/`+event.meetup_id}  className="btn btn-primary" target="_blank" rel="noopener noreferrer" alt={t('events.review')}>
                  <FaMeetup /> 
                </a> }
              </td>
              <td>
                <Button variant="warning" onClick={() => setEditEvent(event)}>
                  <FaEdit />
                </Button>{' '}
                <Button variant="danger" onClick={() => handleDeleteEvent(event.id)}>
                  <FaEraser />
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>

      <Pagination>
        {[...Array(totalPagesBelow1000).keys()].map((number) => (
          <Pagination.Item
            key={number + 1}
            active={number + 1 === currentPage}
            onClick={() => setCurrentPage(number + 1)}
          >
            {number + 1}
          </Pagination.Item>
        ))}
      </Pagination>

       <h3>{t('events.specialmeetups_title')}</h3>
      <Table striped bordered hover>
        <thead>
            <tr>
                <th onClick={() => handleSort('id')}>
                {t('events.id')} {sortConfig.key === 'id' && (sortConfig.direction === 'asc' ? '↑' : '↓')}
                </th>
                <th onClick={() => handleSort('name')}>
                {t('events.name')} {sortConfig.key === 'name' && (sortConfig.direction === 'asc' ? '↑' : '↓')}
                </th>
                <th onClick={() => handleSort('date')}>
                {t('events.date')} {sortConfig.key === 'date' && (sortConfig.direction === 'asc' ? '↑' : '↓')}
                </th>
                <th>{t('events.links')}</th>
                <th>{t('events.actions')}</th>
            </tr>
        </thead>
        <tbody>
          {paginatedEventsAboveOrEqual1000.map((event) => (
            <tr key={event.id}>
              <td>{event.id}</td>
              <td>{event.name}</td>
              <td>{event.date.split('T')[0]}</td>
              <td className='text-center'>
                {event.review &&<a href={event.review} target="_blank" className="btn btn-secondary " rel="noopener noreferrer" alt={t('events.review')}>
                  <FaAlignJustify /> 
                </a>  }
                {event.summary &&<a href={event.summary} target="_blank" className="btn btn-secondary" rel="noopener noreferrer" alt={t('events.summary')}>
                  <FaYoutubeSquare  /> 
                </a> }
                {event.meetup_id &&<a href={`https://meetup.com/madrid-pianomeetups/events/`+event.meetup_id}  className="btn btn-primary" target="_blank" rel="noopener noreferrer" alt={t('events.review')}>
                  <FaMeetup /> 
                </a> }
              </td>
              <td>
                <Button variant="warning" onClick={() => setEditEvent(event)}>
                  <FaEdit />
                </Button>{' '}
                <Button variant="danger" onClick={() => handleDeleteEvent(event.id)}>
                  <FaEraser />
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>

      <Pagination>
        {[...Array(totalPagesAboveOrEqual1000).keys()].map((number) => (
          <Pagination.Item
            key={number + 1}
            active={number + 1 === currentPage}
            onClick={() => setCurrentPage(number + 1)}
          >
            {number + 1}
          </Pagination.Item>
        ))}
      </Pagination>

      {editEvent && (
        <Modal show={true} onHide={() => setEditEvent(null)}>
          <Modal.Header closeButton>
            <Modal.Title>{t('events.edit_title')}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              {Object.keys(newEvent).map((key) => (
                <Form.Group key={key}>
                  <Form.Label>{t(`events.${key}`)}</Form.Label>
                  <Form.Control
                    type={key === 'date' ? 'date' : 'text'}
                    name={key} disabled={key==='id'}
                    value={key ==='date'? editEvent[key].split('T')[0]:editEvent[key] || ''}
                    onChange={handleInputChange}
                  />
                </Form.Group>
              ))}
              <Button variant="primary" onClick={handleEditEvent}>
                {t('events.save_changes')}
              </Button>
            </Form>
          </Modal.Body>
        </Modal>
      )}

     <ToastContainer id="toast" position="position-fixed top-0 end-0 p-3" className="position-fixed top-0 end-0 p-3" style={{zIndex:10000}}>
        <Toast
          bg={toast.variant} animation="true"
          show={toast.show}
          onClose={() => setToast({ show: false, message: '', variant: '' })}
          delay={10000}
          autohide 
        >
          <Toast.Body style={{color:"white"}}>{toast.message}</Toast.Body>
        </Toast>
      </ToastContainer>
    </div>

  );
};

export default ManageEvents;
