import { useState, useEffect, useRef, Fragment } from 'react';
import { Alert, Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import axiosInstance from '../../utilities/axios_instance';
import './WhatsAppChat.css';

const WhatsAppChat = () => {
  const [senders, setSenders] = useState([]);
  const [currentSender, setCurrentSender] = useState(null);
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState('');
  const [latestTimestamp, setLatestTimestamp] = useState(null);
  const [offset, setOffset] = useState(0);
  const wabUrl = process.env.REACT_APP_MESSAGING_API;
  const wabMedia= process.env.REACT_APP_WAB_MEDIA;
  const { t } = useTranslation();
  const [alert, setAlert] = useState('');
  const [error, setError] = useState(false);
  const pollInterval = process.env.WHATSAPP_POLL_INTERVAL || 15000;
  const [reactionPopupData, setReactionPopupData] = useState(null);
  const [intervalObj,setIntervalObj]= useState(null);
  
  const messagesEndRef = useRef(null);

  const scrollToBottom = () => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollTop = messagesEndRef.current.scrollHeight;
    }
  };
  const handleReactionClick = (reactions) => {
    setReactionPopupData(reactions);
  };
  
  const closeReactionPopup = () => {
    setReactionPopupData(null);
  };

  const fetchConversations = async (isUpdate = false) => {
    try {
      const params = isUpdate && latestTimestamp ? { timestamp: latestTimestamp } : { offset };
      const response = await axiosInstance.get(wabUrl + '/fetch_conversations', { params });

      const fetchedSenders = isUpdate
        ? [...response.data, ...senders]
        : [...senders, ...response.data];

      setSenders(fetchedSenders);

      if (isUpdate && response.data.length > 0) {
        const latestMessageTime = response.data[0].messages[0].createdAt;
        setLatestTimestamp(latestMessageTime);
        if (currentSender) {
          fetchSenderMessages(currentSender);
        }
      }
    } catch (error) {
      console.error('Error fetching conversations:', error);
    }
  };

  const fetchSenderMessages = async (sender) => {
    try {
      const response = await axiosInstance.get(`${wabUrl}/fetch_messages?sender_id=${sender.phone}`);
      setMessages(response.data);
      setCurrentSender(sender);
      await axiosInstance.post(`${wabUrl}/mark_as_read`, { phone: sender.phone });
    } catch (error) {
      console.error('Error fetching sender messages:', error);
      setAlert('Error obteniendo mensajes: ' + error);
      setError(true);
    }
  };

  const sendMessage = async () => {
    if (!newMessage || !currentSender) return;

    try {
      await axiosInstance.post(`${wabUrl}/send_message`, {
        to: currentSender.phone,
        text: newMessage,
        member_id: currentSender.id,
      });
      setNewMessage('');
      fetchSenderMessages(currentSender);
    } catch (error) {
      console.error('Error sending message:', error);
      setAlert('Error enviando mensaje: ' + error);
      setError(true);
    }
  };

  const handleSenderScroll = (e) => {
    const { scrollTop, clientHeight, scrollHeight } = e.target;
    if (scrollHeight - scrollTop <= clientHeight + 50) {
      setOffset(offset + 100);
      fetchConversations();
    }
  };

  useEffect(() => {
    fetchConversations();
    debugger;
    if (intervalObj==null)
    {
      setIntervalObj( setInterval(() => fetchConversations(true), pollInterval));
    }
    
    return () => clearInterval(intervalObj);
  }, [intervalObj]);

  const invokeCallMedia = async () => {
    const res = await axiosInstance.get(`${wabUrl}/wab_get_media`);
    //console.log(res);
  };

  const renderMessageContent = (msg) => {
    let mdata= typeof msg.message_data=="object"?msg.message_data:JSON.parse(msg?.message_data);
    switch (msg.message_type) {
      case 'text':
        return <p className="mb-1 message-text" >{mdata.body}</p>;

      case 'image':
      case 'video':
      case 'audio':
            console.log(mdata.mediaPath);
        return (
          <>
            <a href={wabMedia+"/"+mdata.mediaPath} target="_blank" rel="noopener noreferrer">
              {msg.message_type === 'audio' ? (
                <audio controls src={wabMedia+"/"+mdata.mediaPath}></audio>
              ) : (
                <img src={wabMedia+"/"+mdata.mediaPath}
                  alt={`${msg.message_type} media`}
                  className="message-image"
                />
              )}
              
            </a>
            {mdata.caption && <p className="mb-1 message-text" >{mdata.caption}</p>}
          </>
        );

      case 'location':
        return (
          <div>
            <p>{t('Location')}: {mdata.locationName || t('Unknown')}</p>
            <p>
              {mdata.latitude}, {mdata.longitude}
            </p>
          </div>
        );

      case 'contacts':
        return (
          <div>
            {mdata.map((contact, index) => (
              <div key={index}>
                <p>{t('Name')}: {contact.name}</p>
                <p>{t('Phone')}: {contact.phone}</p>
              </div>
            ))}
          </div>
        );

      case 'interactive':
        return (
          <div>
            <p>{t('Interactive')}: {mdata.interactiveType}</p>
            <p>{t('Title')}: {mdata.title}</p>
            {mdata.description && <p>{t('Description')}: {mdata.description}</p>}
          </div>
        );

      case 'reaction':
        return ; //Reactions are not shown this way
          /* <p>
            {t('Reaction')}: {mdata.reactionType} {t('to')} {mdata.reactedToMessageId}
          </p>*/
        

      default:
        return <p>{t('Unsupported message type')}</p>;
    }
  };
  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  return (
    <Fragment>
      <div className="container-fluid"> 
        <h2 className="my-3 mb-4">{t('wab.title')}</h2>
        {/*<Button onClick={invokeCallMedia}>GET MEDIA</Button>*/}
        {alert && (
          <Alert
            variant={error ? 'danger' : 'success'}
            onClose={() => setAlert('')}
            dismissible
          >
            {alert}
          </Alert>
        )}

        <div className="row">
          <div
            className="col-4 border-end overflow-scroll senders"
            onScroll={handleSenderScroll}
          >
            {senders.map((sender, index) => (
              <div
                key={index}
                className={`senderButton p-2 ${
                  sender.phone === currentSender?.phone ? 'selected' : ''
                } ${sender.latest.readStatus === 'UNREAD' ? 'unread' : ''}`}
                onClick={() => fetchSenderMessages(sender.sender_details)}
              >
                <p className={`mb-1 ${sender.latest.readStatus === 'UNREAD' ? 'unread' : ''}`}>
                  {sender.sender}
                  <p className="senderPhone"> {sender.phone}</p>
                  <p
                    className={`latestMessage ${
                      sender.latest?.readStatus === 'UNREAD' ? 'unread' : ''
                    }`}
                  >
                    {sender.latest?.message || t('wab.no_messages_yet')}
                  </p>
                </p>
              </div>
            ))}
          </div>

          <div className="col-8 messages-parent">
            {currentSender && (
              <div className="recipient row">
                <div className="col-10">
                  {currentSender.firstname} {currentSender.lastname} ({currentSender.alias})
                  <p className="senderPhone"> {currentSender.phone} </p>
                </div>
                <div className="col-2">
                  {currentSender.numSocio && (
                    <img
                      className="img-responsive"
                      src={`https://pianomeetups.com/members/photos/${('000' + currentSender.numSocio).slice(-3)}.jpg`}
                      alt="Member profile"
                    />
                  )}
                </div>
              </div>
            )}

            <div className="messages overflow-auto border mb-3" ref={messagesEndRef}>
              {messages.map((msg, index) => (
                <><div
                  key={index} id={msg.id}
                  className={`d-flex ${
                    msg.type.startsWith('OUT') ? 'justify-content-end sent' : 'justify-content-start received'
                  } my-3 ${msg.message_type}`}
                >
                  <div
                    className={`p-2 border rounded ${msg.type.startsWith('OUT') ? 'sent' : 'received'}`}  
                    >
                    {renderMessageContent(msg)}
                    <div className="message-metadata">
                      <span className="message-date text-muted">
                        {new Date(msg.created_at).toLocaleString()}
                      </span>
                      {msg.type.startsWith('OUT') && (
                        <span className={`message-status text-muted ${msg.message_status}`}>
                          {msg.message_status === 'RECEIVED' || msg.message_status === 'READ'
                            ? '✓✓'
                            : msg.message_status === 'DELIVERED'
                            ? '✓'
                            : msg.message_status === 'SENT'
                            ? '?'
                            : 'x'}
                        </span>
                      )}
                    </div>
                  </div>  
                  {msg.message_data?.reactions && msg.message_data.reactions?.length > 0 && (
                    <div
                      id={"reaction-" + msg.id}
                      className={`message-reactions message-reaction-${msg.type.startsWith('OUT') ? 'sent' : 'received'}`}
                      onClick={() => handleReactionClick(msg.reactions)} // Trigger popup on click
                    >
                      {Object.entries(
                        msg.message_data.reactions.reduce((acc, reaction) => {
                          acc[reaction.emoji] = acc[reaction.emoji] || { count: 0, senders: [] };
                          acc[reaction.emoji].count += 1;
                          acc[reaction.emoji].senders.push(reaction.sender);
                          return acc;
                        }, {})
                      ).map(([emoji, data]) => (
                        <span key={emoji} className="reaction-item">
                          {emoji} {data.count > 1 && <span className="reaction-counter">{data.count}</span>}
                        </span>
                      ))}
                    </div>
                  )}
                </div>
                </>
              ))}
            </div>

            <div className="d-flex">
              <input
                type="text"
                className="form-control"
                value={newMessage}
                onChange={(e) => setNewMessage(e.target.value)}
                placeholder={t('wab.type_your_message')}
              />
              <button className="btn btn-primary ms-2" onClick={sendMessage}>
                {t('wab.send')}
              </button>
            </div>
          </div>
        </div>
      </div>
      {reactionPopupData && (
        <div className="reaction-popup">
          <div className="reaction-popup-content">
            <h4>Reactions</h4>
            <ul>
              {reactionPopupData.map((reaction, index) => (
                <li key={index}>
                  {reaction.sender} reacted with {reaction.emoji}
                </li>
              ))}
            </ul>
            <button onClick={closeReactionPopup} className="btn btn-secondary">
              Close
            </button>
          </div>
        </div>
      )}

    </Fragment>
  );
};

export default WhatsAppChat;
