import React, { useState, useEffect, Fragment } from "react";
import axiosInstance from '../../utilities/axios_instance';
import { useTranslation } from "react-i18next";
import "bootstrap/dist/css/bootstrap.min.css";
import { Alert, Button, Col, Container, Form, Row, Table } from 'react-bootstrap';

const PrepareEvent = () => {
  const { t } = useTranslation();
  const [events, setEvents] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState('');
  const [attendanceFile, setAttendanceFile] = useState(null);
  const [attendees, setAttendees] = useState([]);
  const [resultMessage, setResultMessage] = useState('');
  const [resultSeverity, setResultSeverity] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [sortColumn, setSortColumn] = useState(null); 
  const [sortOrder, setSortOrder] = useState('asc'); 
  const [selectedColumn, setSelectedColumn] = useState(null);

  const eventsAPIUrl = process.env.REACT_APP_EVENTS_API;

  useEffect(() => {
    const fetchEvents = async () => {
      try {
        const response = await axiosInstance.get(`${eventsAPIUrl}/events`, { params: { ordered: 'true' } });
        if (Array.isArray(response.data.events)) {
          setEvents(response.data.events);
        } else {
          console.error("Unexpected API response format:", response.data);
        }
      } catch (error) {
        console.error(t('prepare_event.error_fetching_events'), error.message);
      }
    };
    fetchEvents();
  }, []);

  const handleFileChange = (e) => {
    setAttendanceFile(e.target.files[0]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    if (!selectedEvent || !attendanceFile) {
      setResultMessage(t('prepare_event.missing_fields'));
      setResultSeverity("danger");
      setIsSubmitting(false);
      return;
    }

    const formData = new FormData();
    formData.append('eventId', selectedEvent);
    formData.append('attendance', attendanceFile);

    try {
      const response = await axiosInstance.post(`${eventsAPIUrl}/next_event_order`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });

      if (response.data.success) {
        setAttendees(response.data.attendees);
        setResultMessage(t('prepare_event.success_message'));
        setResultSeverity("success");
      } else {
        setResultMessage(t('prepare_event.error_processing') + response.data.message);
        setResultSeverity("danger");
      }
    } catch (error) {
      console.error(t('prepare_event.error_submitting'), error.message);
      setResultMessage(t('prepare_event.error_submitting') + error.message);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleSort = (column) => {
    const newOrder = sortColumn === column && sortOrder === 'asc' ? 'desc' : 'asc';
    setSortColumn(column);
    setSortOrder(newOrder);

    const sortedAttendees = [...attendees].sort((a, b) => {
      if (a[column] < b[column]) return newOrder === 'asc' ? -1 : 1;
      if (a[column] > b[column]) return newOrder === 'asc' ? 1 : -1;
      return 0;
    });

    setAttendees(sortedAttendees);
  };


  const copyToClipboard = (part = "whole") => {
    let tableText = "";
    switch (part) {
      case "left":
        tableText = `${t("prepare_event.header_name")}\t${t("prepare_event.header_rsvp")} \n`;
        break;
      case "right":
        tableText = `${t("prepare_event.header_amount")}\t${t("prepare_event.header_payment")}\t${t("prepare_event.header_comments")}\t${t("prepare_event.header_rsvp")}\n`;
        break;
      default:
        tableText = `${t("prepare_event.header_name")}\t${t("prepare_event.header_member_type")}\t ${t("prepare_event.header_amount")}\t${t("prepare_event.header_payment")}\t${t("prepare_event.header_comments")}\t${t("prepare_event.header_rsvp")}\n`;
        break;
    }

    attendees.forEach((attendee) => {
      switch (part) {
        case "left":
          tableText += `${attendee.Name}\t${attendee.RSVPDate}\n`;
          break;
        case "right":
          tableText += `${attendee.amount}\t${attendee.payment}\t${attendee.comments}\t${attendee.RSVPDate}\n`;
          break;
        default:
          tableText += `${attendee.Name}\t${attendee.type}\t${attendee.amount}\t${attendee.payment}\t${attendee.comments}\t${attendee.RSVPDate}\n`;
          break;
      }
    });

    navigator.clipboard
      .writeText(tableText)
      .then(() => {
        setResultMessage(t('prepare_event.copied_message', { part }));
        setResultSeverity("success");
      })
      .catch((error) => {
        setResultMessage(t('prepare_event.copy_error'));
        setResultSeverity("danger");
      });
  };

  const closeAlert= (e) =>{
      e.preventDefault();
      setResultMessage("");
  }

  return (
    <Fragment>
      <Container>
        <h1>{t('prepare_event.title')}</h1>
        {resultMessage && (
          <Alert variant={resultSeverity} dismissible onClose={closeAlert}>{resultMessage}</Alert>
        )}
        <Form onSubmit={handleSubmit}>
          <Row>
            <Col sm={4}>
              <Form.Group controlId="eventSelect">
                <Form.Label>{t('prepare_event.select_event_label')}</Form.Label>
                <Form.Select
                  value={selectedEvent}
                  onChange={(e) => setSelectedEvent(e.target.value)}
                  required
                >
                  <option value="">{t('prepare_event.select_event_placeholder')}</option>
                  {events.map((event) => (
                    <option key={event.id} value={event.id}>
                      {`${event.name} (${new Date(event.date).toLocaleDateString()})`}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
            </Col>
            <Col sm={4}>
              <Form.Group controlId="attendanceFile">
                <Form.Label>{t('prepare_event.attendance_label')}</Form.Label>
                <Form.Control
                  type="file"
                  accept=".xls,.xlsx,.csv"
                  onChange={handleFileChange}
                  required
                />
              </Form.Group>
            </Col>
            <Col sm={4} className="d-flex align-items-end">
              <Button type="submit" disabled={isSubmitting}>
                {isSubmitting ? t('prepare_event.submitting') : t('prepare_event.submit_button')}
              </Button>
            </Col>
          </Row>
        </Form>
        {attendees.length > 0 && (
          <div className="mt-4">
            <h2>{t('prepare_event.attendees_header')}</h2>
            <div className="d-flex gap-2 mb-3">
              <Button variant="secondary" onClick={() => copyToClipboard("whole")}>
                {t('prepare_event.copy_table')}
              </Button>
              <Button variant="secondary" onClick={() => copyToClipboard("left")}>
                {t('prepare_event.copy_left')}
              </Button>
              <Button variant="secondary" onClick={() => copyToClipboard("right")}>
                {t('prepare_event.copy_right')}
              </Button>
            </div>
            <Table striped bordered>
              <thead>
                <tr>
                  {['Name', 'RSVPDate', 'Type', 'Amount', 'Payment', 'Comments', 'RSVPDate'].map((col) => (
                    <th
                      key={col}
                      onClick={() => handleSort(col)}
                      className={selectedColumn === col ? 'bg-primary text-white' : ''}
                    >
                      {t(`prepare_event.${col.toLowerCase()}`)} {sortColumn === col && (sortOrder === 'asc' ? '↑' : '↓')}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {attendees.map((attendee, rowIndex) => (
                  <tr key={rowIndex}>
                    {['Name', 'RSVPDate', 'type', 'amount', 'payment', 'comments', 'RSVPDate'].map((col) => (
                      <td key={col}>{attendee[col]}</td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        )}
      </Container>
    </Fragment>
  );
};

export default PrepareEvent;
